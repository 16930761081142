import "./styles.scss"

import React from "react"
import { navigate } from "gatsby"
import scrollToElement from "scroll-to-element"

import { Step } from "./components"

const Steps = () => {
  const data = [
    {
      header: {
        title: "Umów sie na spotkanie",
      },
      content: {
        avatar: require("assets/images/customer-zone/klaudia.png")
          .default,
        data: {
          name: "Klaudia Jodłowska",
          role: "Office Manager",
        },
        contact: {
          phone: "48 730 637 064",
          email: "klaudia@citylobzow.pl",
        },
      },
      button: {
        name: "Wypełnij formularz",
        onClick: () => scrollToElement("#contact", { duration: 150 }),
      },
    },
    {
      header: {
        title: "Wybierz mieszkanie",
      },
      content: "Sprawdź nasze mieszkania i podpisz umowę rezerwacyjną",
      button: {
        name: "Wybierz mieszkanie",
        onClick: () => navigate("/mieszkania/"),
      },
    },
    {
      header: {
        title: "Finansowanie",
        subtitle: (
          <p>
            <strong>Potrzebujesz kredytu?</strong>
            <br />
            Skorzystaj teraz z bezpłatnej pomocy eksperta finansowego
          </p>
        ),
      },
      content: {
        avatar: require("assets/images/customer-zone/tomasz.png")
          .default,
        data: {
          name: "Tomasz Jodłowski",
          role: "Doradca finansowy",
        },
        contact: {
          phone: "48 737 497 199",
          email: "tomasz.jodlowski@phinance.pl",
        },
      },
      button: {
        name: "Wypełnij formularz",
        onClick: () => scrollToElement("#contact", { duration: 150 }),
      },
    },
    {
      header: {
        title: "Umowa deweloperska",
      },
      content:
        "Dokonaj wpłat na rachunek powierniczy zgodnie z ustalonym harmonogramem",
    },
    {
      header: {
        title: "Pozwolenie na użytkowanie",
      },
      content:
        "Otrzymujesz informację o uzyskaniu pozwolenia na użytkowanie budynku, w którym znajduje się Twoje mieszkanie",
    },
    {
      header: {
        title: "Odbiór i klucze",
      },
      content:
        "Wraz z akceptacją protokołu zdawczo-odbiorczego otrzymujesz klucze do mieszkania",
    },
  ]
  return (
    <section className="customer-steps">
      <div className="container-fluid">
        {data?.map((item, index) => (
          <Step
            index={index + 1}
            header={item.header}
            content={item.content}
            button={item.button}
          />
        ))}
      </div>
    </section>
  )
}

export default Steps
