import "./styles.scss"

import React from "react"

const Banner = () => {
  return (
    <section
      className="customer-banner"
      style={{
        backgroundImage: `url(${
          require("assets/images/customer-zone/banner-image.jpg")
            .default
        })`,
      }}
    />
  )
}

export default Banner
