import "./styles.scss"

import React from "react"
import clsx from "clsx"

import Button from "components/Button"

const Step = ({ index, header, content, button }) => {
  const isContentString = typeof content === "string"

  return (
    <div className="customer-step">
      <div className={clsx("row", isContentString && "align-items-md-center")}>
        <div className="col-xl-5 col-lg-6">
          <div
            className={clsx(
              "customer-step__counter",
              header?.subtitle && "customer-step__counter--subtitle"
            )}
          >
            <div className="customer-step__counter-icon">{index}</div>
            {header && (
              <div className="customer-step__counter-header">
                <h2 className="h4">{header.title}</h2>
                {header.subtitle && header.subtitle}
              </div>
            )}
          </div>
        </div>
        <div className="col-xl-7 col-lg-6">
          <div
            className={clsx(
              "customer-step__details",
              !isContentString && "customer-step__details--contact",
              header?.subtitle && "customer-step__details--subheader"
            )}
          >
            <div className="customer-step__details-content">
              {isContentString ? (
                <p>{content}</p>
              ) : (
                <div className="customer-step__contact">
                  <div
                    className="customer-step__contact-avatar"
                    style={{ backgroundImage: `url(${content.avatar})` }}
                  />
                  {(content.data || content.contact) && (
                    <div className="customer-step__contact-info">
                      {content.data && (
                        <div className="customer-step__contact-block">
                          <p className="customer-step__contact-title">
                            {content.data.name}
                          </p>
                          <p>{content.data.role}</p>
                        </div>
                      )}
                      {content.contact && (
                        <div className="customer-step__contact-block">
                          <p className="customer-step__contact-title">
                            <a
                              href={`tel:+${content.contact.phone.replaceAll(
                                " ",
                                ""
                              )}`}
                            >
                              +{content.contact.phone}
                            </a>
                          </p>
                          <p>
                            <a href={`mailto:${content.contact.email}`}>
                              {content.contact.email}
                            </a>
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            {button && (
              <div className="customer-step__details-cta">
                <Button className="button--medium" onClick={button.onClick}>
                  {button.name}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step
