import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Steps, Banner } from "page_components/customer-zone"

const CustomerZone = () => {
  const title = "Strefa klienta"

  return (
    <Layout seo={{ title: title }}>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Steps />
      <Banner />
    </Layout>
  )
}

export default CustomerZone
